<template>
<div
    v-if="!isRowHeader"
    :key="`${key}_${studentEnrollmentId}`"
    class="kt-widget5 p-0"
>
    <div v-if="student" class="kt-widget5__item pb-0 pt-2 mb-0">
        <div class="kt-widget5__content p-0" style="justify-content: flex-start;">
            <div
                class="kt-widget5__pic pr-0"
                style="width: 50px; max-height: 49px; overflow: hidden; margin: 0;"
            >
                <StudentAvatar
                    v-if="encryptionEnabled"
                    :student="student"
                    :student-avatar="student.studentAvatar"
                    style="max-height: 38px; max-width: 38px; margin: 6px;"
                    class="kt-widget7__img"
                />
                <UserAvatar
                    v-else
                    :avatar-user="student"
                    style="max-height: 38px; max-width: 38px; margin: 6px;"
                    class="kt-widget7__img"
                >
                    <div class="kt-media">
                        <span class="kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold">
                            {{ initials }}
                        </span>
                    </div>
                </UserAvatar>
            </div>
            <div
                class="kt-widget5__section"
                style="white-space: nowrap; text-align: left; "
            >
                <router-link
                    style="white-space: nowrap;"
                    class="kt-widget5__title pb-0 w-100"
                    :to="{
                        name: 'StudentInfo',
                        params: {
                            studentEnrollmentId,
                        },
                    }"
                    event=""
                    @click.native.stop.prevent="openPanelForStudent"
                >
                    <template v-if="encryptionEnabled">
                        {{ student.maskedStudentName }}
                    </template>
                    <template v-else>
                        {{ student.lastName }}, {{ student.firstName }}
                    </template>
                </router-link>
                <p class="kt-widget5__desc p-0">
                    Homeroom {{ student.homeRoom }}
                </p>
            </div>
        </div>
    </div>
</div>
<div
    v-else
    class="table-header sort"
    :class="{
        'sort-asc': sortIndex == columnIndex && sortOrder === 'asc',
        'sort-desc': sortIndex == columnIndex && sortOrder === 'desc',
    }"
>
    {{ columnTitle }}
</div>
</template>

<script lang="ts">
import StudentAvatar from '../StudentAvatar.vue';
import UserAvatar from '../UserAvatar.vue';
import panelMixins from '../../store/mixins/panelMixins';
import Types from '../../store/Types';

export default {
    name: 'StudentHotColumn',
    components: {
        StudentAvatar,
        UserAvatar,
    },
    mixins: [panelMixins],
    data() {
        return {
            // We'll need to define properties in our data object,
            // corresponding to all of the data being injected from
            // the BaseEditorComponent class, which are:
            // - hotInstance (instance of Handsontable)
            // - row (row index)
            // - col (column index)
            // - prop (column property name)
            // - TD (the HTML cell element)
            // - cellProperties (the cellProperties object for the edited cell)
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
            key: 0,
        };
    },
    computed: {
        columnIndex() {
            return this.col;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        currentPage() {
            return this.$store.state.database.studentDataView.currentPage;
        },
        sortIndex() {
            return this.$store.state.database.studentDataView.sortIndex;
        },
        sortOrder: {
            get() {
                return this.$store.state.database.studentDataView.sortOrder;
            },
            set() {
                this.$store.commit(Types.mutations.TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER);
            },
        },
        record() {
            return this.value;
        },
        column() {
            const { record } = this;
            if (!record) return null;
            return record.column;
        },
        columnName() {
            const { column } = this;
            if (!column) return '';
            return column.name;
        },
        columnTitle() {
            const { column } = this;
            if (!column) {
                return '';
            }
            return column.title;
        },
        isRowHeader() {
            return this.row === 0;
        },
        student() {
            const { record } = this;
            if (!record) return null;
            return record.cellValue || null;
        },
        studentEnrollmentId() {
            const {student, record} = this;
            if (!record || !student) return 0;
            if (this.isRowHeader) return 0;
            return student.hasOwnProperty('studentEnrollmentId') ? student.studentEnrollmentId : 0;
        },
        initials() {
            if (!this.student || this.isRowHeader) return '';
            return (this.student.lastName || '').substring(0, 2);
        },
        classBinding() {
            // TBD: fix this
            return [true ? 'kt-media ml-2' : ''];
        },
    },
    watch: {
        currentPage() {
            this.key += 1;
        },
    },
    methods: {
        openPanelForStudent() {
            const { student, course } = this;
            this.$_panelMixins_openPanelForStudent(student, course);
        },
    },
};
</script>
<style scoped>

.kt-media-student {
  max-height: 2.3rem;
}

.kt-widget__username {
  font-size: 1.1rem !important;
  max-width: 200px !important;
}
</style>
