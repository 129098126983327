var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isRowHeader
    ? _c(
        "div",
        {
          key: `${_vm.key}_${_vm.studentEnrollmentId}`,
          staticClass: "kt-widget5 p-0",
        },
        [
          _vm.student
            ? _c("div", { staticClass: "kt-widget5__item pb-0 pt-2 mb-0" }, [
                _c(
                  "div",
                  {
                    staticClass: "kt-widget5__content p-0",
                    staticStyle: { "justify-content": "flex-start" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget5__pic pr-0",
                        staticStyle: {
                          width: "50px",
                          "max-height": "49px",
                          overflow: "hidden",
                          margin: "0",
                        },
                      },
                      [
                        _vm.encryptionEnabled
                          ? _c("StudentAvatar", {
                              staticClass: "kt-widget7__img",
                              staticStyle: {
                                "max-height": "38px",
                                "max-width": "38px",
                                margin: "6px",
                              },
                              attrs: {
                                student: _vm.student,
                                "student-avatar": _vm.student.studentAvatar,
                              },
                            })
                          : _c(
                              "UserAvatar",
                              {
                                staticClass: "kt-widget7__img",
                                staticStyle: {
                                  "max-height": "38px",
                                  "max-width": "38px",
                                  margin: "6px",
                                },
                                attrs: { "avatar-user": _vm.student },
                              },
                              [
                                _c("div", { staticClass: "kt-media" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold",
                                    },
                                    [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                                  ),
                                ]),
                              ]
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget5__section",
                        staticStyle: {
                          "white-space": "nowrap",
                          "text-align": "left",
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "kt-widget5__title pb-0 w-100",
                            staticStyle: { "white-space": "nowrap" },
                            attrs: {
                              to: {
                                name: "StudentInfo",
                                params: {
                                  studentEnrollmentId: _vm.studentEnrollmentId,
                                },
                              },
                              event: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.openPanelForStudent.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm.encryptionEnabled
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.student.maskedStudentName) +
                                      " "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.student.lastName) +
                                      ", " +
                                      _vm._s(_vm.student.firstName) +
                                      " "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c("p", { staticClass: "kt-widget5__desc p-0" }, [
                          _vm._v(
                            " Homeroom " + _vm._s(_vm.student.homeRoom) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "table-header sort",
          class: {
            "sort-asc":
              _vm.sortIndex == _vm.columnIndex && _vm.sortOrder === "asc",
            "sort-desc":
              _vm.sortIndex == _vm.columnIndex && _vm.sortOrder === "desc",
          },
        },
        [_vm._v(" " + _vm._s(_vm.columnTitle) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }